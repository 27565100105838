import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "../styles/explica.module.css";
import { iaCardReponse } from "../services/iaService";
import Quiz from "./quiz";
import MicIcon from "@mui/icons-material/Mic";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function EduAmigoExplica() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recognition, setRecognition] = useState(null); // Armazenando a instância

  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!recognition) {
      const newRecognition = new window.webkitSpeechRecognition();
      newRecognition.lang = "pt-BR";
      newRecognition.interimResults = false;
      newRecognition.maxAlternatives = 1;
      setRecognition(newRecognition); // Armazenando a instância no estado
    }
  }, [recognition]);

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRecordAudio = () => {
    if (!recognition) {
      alert("Reconhecimento de voz não está disponível.");
      return;
    }

    if (isRecording) {
      recognition.stop(); // Para a gravação se já estiver gravando
      setIsRecording(false);
    } else {
      recognition.onstart = () => {
        setIsRecording(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setMessage(transcript); // Coloca o texto no campo de mensagem
        setIsRecording(false);

        // Envia a mensagem assim que o reconhecimento for concluído
        handleSendMessage(transcript);
      };

      recognition.onerror = (event) => {
        console.error("Erro no reconhecimento de voz:", event.error);
        setIsRecording(false);
      };

      recognition.start(); // Inicia o reconhecimento de voz
    }
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      const newMessage = { user: true, text: message };
      setChat([...chat, newMessage]);
      setMessage("");

      setIsLoadingCards(true);
      setIsModalOpen(true);

      try {
        let cardResponse = await iaCardReponse({
          message: message,
        });

        if (cardResponse?.data) {
          setCards(cardResponse?.data);
        }

        setIsLoadingResponse(false);
      } catch (error) {
        setIsLoadingResponse(false);
        setIsLoadingCards(false);
        setIsModalOpen(false);
        console.error("Erro ao carregar o quiz", error);
        toast.error(
          "Houve um problema ao carregar o quiz. Tente novamente mais tarde."
        );
      } finally {
        setIsLoadingCards(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  return (
    <>
      <main className={styles.main}>
        <nav className={styles.nav}>
          <div className={styles.logo}>
            <a href="/">eduamigo</a>
          </div>
          <div className={styles.buttonContainer}>
            <button className={`${styles.button} ${styles.buttonPurple}`}>
              EduAmigo Explica
            </button>
            <button
              onClick={() => navigate("/fale")}
              className={`${styles.button} ${styles.buttonWhite}`}
            >
              Fale com Eduamigo
            </button>
          </div>
        </nav>

        {chat.length === 0 && (
          <div className={styles.hint}>
            <div className={styles.titleMessage}>
              <h2>Crie guias de estudo com o Eduamigo</h2>
              <h2>Peça para ele criar cartões sobre algum tema</h2>
            </div>
            <img
              className={styles.image}
              src="/images/hero3topright.png"
              alt=""
            />
          </div>
        )}

        {chat.length > 0 && (
          <div className={styles.chatBox}>
            {chat.map((msg, index) => (
              <div
                key={index}
                className={`${styles.message} ${styles.userMessage}`}
              >
                <>
                  {msg.user ? (
                    msg.text
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                  )}
                </>
              </div>
            ))}
            <div ref={chatEndRef} />
          </div>
        )}

        <div className={styles.inputSection}>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              onKeyDown={handleKeyDown}
              placeholder="Digite sua dúvida aqui"
              className={styles.inputField}
            />
            <div className={styles.sendButtons}>
              <button
                onClick={() => handleSendMessage(message)}
                className={styles.sendButton}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                onClick={handleRecordAudio}
                className={styles.recordButton}
              >
                {isRecording ? "🔴" : <MicIcon />}
              </button>
            </div>
          </div>
        </div>
      </main>
      {isLoadingCards ? (
        <div className={styles.loadingCards}>
          <div className={styles.loadingSpinnerQuiz}></div>
          <span>Carregando o quiz...</span>
        </div>
      ) : (
        <Quiz isOpen={isModalOpen} onClose={handleCloseModal} cards={cards} />
      )}
    </>
  );
}

export default EduAmigoExplica;
