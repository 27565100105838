import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "../styles/fale.module.css";
import {
  iaTextoResponse,
  iaAudioResponse,
  iaCardReponse,
  iaImagemResponse,
} from "../services/iaService";
import Quiz from "./quiz";
import MicIcon from "@mui/icons-material/Mic";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import PauseIcon from "@mui/icons-material/Pause";

function EduAmigoFale() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(false);
  const [isLoadingCards, setIsLoadingCards] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(null);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recognition, setRecognition] = useState(null); // Armazenando a instância

  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!recognition) {
      const newRecognition = new window.webkitSpeechRecognition();
      newRecognition.lang = "pt-BR";
      newRecognition.interimResults = false;
      newRecognition.maxAlternatives = 1;
      setRecognition(newRecognition); // Armazenando a instância no estado
    }
  }, [recognition]);

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const handleOpenModal = async (messageIndex) => {
    setCurrentMessageIndex(messageIndex);
    setCards([]);

    const selectedMessage = chat[messageIndex];
    if (selectedMessage && selectedMessage.text) {
      setIsLoadingCards(true);

      try {
        let cardResponse = await iaCardReponse({
          message: selectedMessage.text,
        });
        if (cardResponse?.data) {
          setCards(cardResponse?.data);
          setIsLoadingCards(false);
          setIsModalOpen(true);
        }
      } catch (error) {
        setIsLoadingCards(false);
        setIsModalOpen(false);
        console.error("Quiz loading failed", error);
        toast.error(
          "Houve um problema ao carregar o quiz. Tente novamente mais tarde."
        );
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Função para obter e exibir a imagem como nova mensagem com spinner
  const handleFetchImage = async (messageIndex) => {
    const lastUserMessage = chat.findLast((msg) => msg.user === true);
    const selectedMessage = chat[messageIndex];
    if (selectedMessage && selectedMessage.text) {
      // Adiciona a mensagem temporária com spinner ao chat
      const tempMessage = {
        user: false,
        isImage: true,
        isLoadingImage: true, // Marca a mensagem como em carregamento
      };
      setChat((prevChat) => [...prevChat, tempMessage]);

      try {
        setIsLoadingImage(true);
        let imageResponse = await iaImagemResponse({
          message: `${lastUserMessage.text} divertido, colorido, educacional`,
        });
        const imageUrl = imageResponse?.data?.url;
        if (imageUrl) {
          // Substitui a mensagem temporária pela imagem
          setChat((prevChat) => {
            const updatedChat = [...prevChat];
            updatedChat[updatedChat.length - 1] = {
              user: false,
              isImage: true,
              imageUrl: imageUrl,
              isLoadingImage: false,
            };
            return updatedChat;
          });
        }
      } catch (error) {
        toast.error(
          "Houve um problema ao carregar a ilustração. Tente novamente mais tarde."
        );
        console.error("Image generation failed", error);
        setChat((prevChat) => {
          const updatedChat = [...prevChat];
          updatedChat[updatedChat.length - 1].isLoadingImage = false;
          return updatedChat;
        });
      }
      setIsLoadingImage(false);
    }
  };

  // Função para iniciar a gravação e transcrição do áudio
  const handleRecordAudio = () => {
    if (!recognition) {
      alert("Reconhecimento de voz não está disponível.");
      return;
    }

    if (isRecording) {
      recognition.stop(); // Para a gravação se já estiver gravando
      setIsRecording(false);
    } else {
      recognition.onstart = () => {
        setIsRecording(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setMessage(transcript); // Coloca o texto no campo de mensagem
        setIsRecording(false);

        // Envia a mensagem assim que o reconhecimento for concluído
        handleSendMessage(transcript);
      };

      recognition.onerror = (event) => {
        console.error("Erro no reconhecimento de voz:", event.error);
        setIsRecording(false);
      };

      recognition.start(); // Inicia o reconhecimento de voz
    }
  };

  const handleSendMessage = async () => {
    if (message.trim()) {
      const newMessage = { user: true, text: message };
      setChat([...chat, newMessage]);
      setMessage("");
      try {
        setIsLoadingResponse(true);
        let iaResponse = await iaTextoResponse({ message: message });
        let chatResponse = {
          user: false,
          text: iaResponse?.data?.resposta,
          audioSource: "",
          isPlaying: false,
          audioInstance: null,
          isLoading: false,
        };

        setChat((prevChat) => [...prevChat, chatResponse]);
        setIsLoadingResponse(false);
        setCards([]);
        setCurrentMessageIndex(null);
      } catch (error) {
        setIsLoadingResponse(false);
        console.error("Text-to-speech failed", error);
        toast.error(
          "Houve um problema ao enviar a mensagem. Tente novamente mais tarde."
        );
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handlePlayPauseAudio = async (index) => {
    const newChat = [...chat];
    const currentMessage = newChat[index];

    if (currentAudioIndex !== null && currentAudioIndex !== index) {
      // Pause the currently playing audio
      const previousMessage = newChat[currentAudioIndex];
      previousMessage.audioInstance?.pause();
      previousMessage.isPlaying = false;
      setCurrentAudioIndex(null);
    }

    if (!currentMessage.audioSource && !currentMessage.isLoading) {
      setLoadingAudio(true);
      currentMessage.isLoading = true;
      setChat(newChat);

      try {
        let audioResponse = await iaAudioResponse({
          message: currentMessage.text,
        });
        const audio = new Audio(
          `data:audio/mp3;base64,${audioResponse?.data?.audio}`
        );

        currentMessage.audioSource = audioResponse?.data?.audio;
        currentMessage.audioInstance = audio;
        currentMessage.isPlaying = true;
        currentMessage.isLoading = false;
        setCurrentAudioIndex(index);
        setChat([...newChat]);
        audio.play();

        setLoadingAudio(false);

        audio.onended = () => {
          currentMessage.isPlaying = false;
          setCurrentAudioIndex(null);
          setChat([...newChat]);
        };
      } catch (error) {
        console.error("Audio generation failed", error);
        currentMessage.isLoading = false;
        setLoadingAudio(false);
        toast.error(
          "Houve um problema ao carregar o áudio. Tente novamente mais tarde."
        );
      }

      setChat(newChat);
    } else if (currentMessage.isPlaying) {
      currentMessage.audioInstance.pause();
      currentMessage.isPlaying = false;
      setCurrentAudioIndex(null);
      setChat(newChat);
    } else {
      currentMessage.audioInstance.play();
      currentMessage.isPlaying = true;
      setCurrentAudioIndex(index);
      setChat(newChat);
    }
  };

  return (
    <>
      <main className={styles.main}>
        <nav className={styles.nav}>
          <div className={styles.logo}>
            <a href="/">eduamigo</a>
          </div>
          <div className={styles.buttonContainer}>
            <button
              onClick={() => navigate("/explica")}
              className={`${styles.button} ${styles.buttonWhite}`}
            >
              EduAmigo Explica
            </button>
            <button className={`${styles.button} ${styles.buttonPurple}`}>
              Fale com Eduamigo
            </button>
          </div>
        </nav>

        {chat.length === 0 && (
          <div className={styles.hint}>
            <div className={styles.titleMessage}>
              <h2>Olá, amigo vamos começar a conversar?</h2>
              <h2>Para começar, digite sua dúvida que eu vou te responder</h2>
            </div>
            <img className={styles.image} src="/images/hero4.webp" alt="" />
          </div>
        )}

        {chat.length > 0 && (
          <div className={styles.chatBox}>
            {chat.map((msg, index) => (
              <div
                key={index}
                className={`${styles.message} ${
                  msg.user ? styles.userMessage : styles.iaMessage
                }`}
              >
                {msg.isImage ? (
                  msg.isLoadingImage ? (
                    <div className={styles.loadingImageMessage}>
                      <div className={styles.spinner}></div>
                      <span>Gerando ilustração...</span>
                    </div>
                  ) : (
                    <img
                      src={msg.imageUrl}
                      alt="Ilustração"
                      className={styles.chatImage}
                    />
                  )
                ) : (
                  <>
                    {!msg.user ? (
                      <img
                        className={styles.iaAvatar}
                        src="/images/avatar-male.webp"
                        alt=""
                        width={85}
                      />
                    ) : null}
                    {msg.user ? (
                      msg.text
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                    )}
                  </>
                )}

                {!msg.user && !msg.isImage && (
                  <div className={styles.audioControls}>
                    <div className="d-flex gap-2 flex-wrap">
                      <button
                        className={styles.playPauseButton}
                        onClick={() => handlePlayPauseAudio(index)}
                        disabled={msg.isLoading}
                      >
                        {msg.isPlaying ? (
                          <>{<PauseIcon />} Reproduzir</>
                        ) : (
                          <>{<VolumeUpIcon />} Reproduzir</>
                        )}
                      </button>
                      <button
                        onClick={() => handleOpenModal(index)}
                        className={styles.playPauseButton}
                      >
                        {<LightbulbIcon />} EduAmigo Explica
                      </button>
                      <button
                        onClick={() => handleFetchImage(index)}
                        className={styles.playPauseButton}
                        disabled={isLoadingImage}
                      >
                        {isLoadingImage ? (
                          "Carregando..."
                        ) : (
                          <>{<InsertPhotoIcon />} Ilustração</>
                        )}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {isLoadingResponse && (
              <div className={styles.loadingSpinner}>
                <div className={styles.spinner}></div>
                <span>Estou pensando...</span>
              </div>
            )}
            <div ref={chatEndRef} />
          </div>
        )}

        <div className={styles.inputSection}>
          <div className={styles.inputContainer}>
            <input
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              onKeyDown={handleKeyDown}
              placeholder="Digite sua dúvida aqui"
              className={styles.inputField}
            />
            <div className={styles.sendButtons}>
              <button
                onClick={() => handleSendMessage(message)}
                className={styles.sendButton}
              >
                <ArrowUpwardIcon />
              </button>
              <button
                onClick={handleRecordAudio}
                className={styles.recordButton}
              >
                {isRecording ? "🔴" : <MicIcon />}
              </button>
            </div>
          </div>
        </div>
      </main>
      {isLoadingCards ? (
        <div className={styles.loadingCards}>
          <div className={styles.loadingSpinnerQuiz}></div>
          <span>Carregando o quiz...</span>
        </div>
      ) : (
        <Quiz isOpen={isModalOpen} onClose={handleCloseModal} cards={cards} />
      )}
    </>
  );
}

export default EduAmigoFale;
